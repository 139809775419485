/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// parallax hero
	$(window).load(function () {
		var heroContainerHeight = $('.hero_container').height(),
			parallaxWrapper = $('.parallax_wrapper'),
			windowWidth = $(window).width();

		if (windowWidth >= 1040) {
			parallaxWrapper.css({
				'top': heroContainerHeight
			});
		}
	});

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {

				var mobileToggle = $('.mobile_nav_toggle'),
					mainNav = $('.main_nav'),
					mainNavLink = $('.main_nav > li > a');

				mobileToggle.on('click', function (e) {
					e.preventDefault();
					mainNav.slideToggle();
				});

				mainNavLink.on('click', function (e) {
					if ($(this).attr('href') === '#') {
						e.preventDefault();
					}
				});

				// sliders
				var hero_slides = $('#slides');

				if (hero_slides.length) {
					hero_slides.slidesjs({
						width: 1600,
						height: 783,
						navigation: {
							active: false
						},
						pagination: {
							active: false
						},
						play: {
							auto: true,
							pauseOnHover: true
						}
					});
				}
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');

		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
